@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: auto;
  font-family: -apple-system, "Varela Round", sans-serif;

  overflow: auto;
  /* background: black; */
  /* background: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%); */
  /* animation: gradient 15s ease infinite; */
  /* background-size: 400% 400%; */
  /* background-attachment: fixed; */
}

.vision {
  background: url(./images/bg.png) top center no-repeat;
  background-size: cover;
}